// TODO: Split into multiple helper files with specific use cases
import {
  addDays, differenceInDays, addMonths, differenceInMonths, format
} from "date-fns";
import { pascalize } from "humps";
import { groupBy, isEmpty, kebabCase } from "lodash";
import { getPrecision } from "@/api/user/authApi";

import { imageFileTypes } from "@/utils/dynamic/constants";
import appSettings from "@/settings";

export const actionCreator = (type, payload) => ({
  type,
  payload,
});

export const skeletonLoadingRows = (rows = 5) => Array(rows).fill({});

// Attachment utils

export const checkIfAttachmentIsImage = (url) => {
  try {
    return imageFileTypes.includes(url.split(".").pop().toLowerCase());
  } catch (error) {
    return false;
  }
};

function trimUrl(url) {
  let newUrl = url;
  while (url?.startsWith("/")) {
    newUrl = url?.substring(1);
  }
  return newUrl;
}

export const getAttachmentUrl = (url) => {
  if (url && url.startsWith("https")) return url;
  return `${appSettings.minioBaseUrl}${trimUrl(url)}`;
};

export const getFileExtension = (filename) => filename.split(".").pop().toLowerCase();

// Permission utils

export const parsePermissions = (data) => {
  const filteredPermissions = [
    // Company
    "Company",
    // Account
    "CompanyAccount",
    // Budget
    "LedgerAccountBudget",
    "PeriodicBudget",
    // Tax
    "TaxGroup",
    "TaxGroupedRule",
    "TaxRate",
    // Price List
    "PriceList",
    "PriceListItem",
    // Bank Reconciliation
    "BankReconciliation",
    "BankReconciliationReconciliationDetail",
    "BankReconciliationTransactionDetail",
    "BankReconciliationAccountDetail",
    // Misc
    "ClosingBalance",
    "Project",
    "Transfer"
  ];
  const permissionsGroup = groupBy(data, "objectType");
  const permissions = {};
  for (const key in permissionsGroup) {
    permissions[key] = { titles: [], permissions: permissionsGroup[key] };
    for (const permission of permissionsGroup[key]) {
      const objectName = pascalize(kebabCase(permission.objectName));
      permissions[key].titles.push(objectName);

      if (filteredPermissions.includes(objectName)) {
        permissions[key][objectName] = [];
      } else if (permission.isChild) {
        permissions[key][objectName] = ["View"];
      } else {
        permissions[key][objectName] = permission.permitted;
      }

      // Handle edge case for model named PDC
      if (objectName.startsWith("Pdc")) {
        permissions[key].titles.push(permission.objectName);

        if (permission.isChild) {
          permissions[key][permission.objectName] = ["View"];
        } else {
          permissions[key][permission.objectName] = permission.permitted;
        }
      }
    }
  }

  return permissions;
};

export function isSnakeCase(str) {
  return typeof str === "string" && str.includes("_");
}

// Number utils

const currencyFormatter = (country, currency) => {
  const precision = getPrecision();

  return new Intl.NumberFormat(country, {
    currencyDisplay: "code",
    style: "currency",
    currency,
    minimumFractionDigits: precision,
  });
};

const currencyFraction = (country, currency) => {
  const precision = getPrecision();

  return new Intl.NumberFormat(country, {
    currencyDisplay: "code",
    style: "currency",
    currency,
    minimumFractionDigits: precision,
  });
};

const numberFraction = (country) => {
  const precision = getPrecision();

  return new Intl.NumberFormat(country, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

export const formatCurrency = (amount, currency = "AED", country = "ar-AR") => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);

  if (absAmount % 1 === 0) {
    const formattedAmount = currencyFormatter(country, currency).format(absAmount);
    return isNegative ? `(${formattedAmount})` : formattedAmount;
  }

  const formattedAmount = currencyFraction(country, currency).format(absAmount);
  return isNegative ? `(${formattedAmount})` : formattedAmount;
};

export const formatNumber = (amount, country = "ar-AR") => numberFraction(country).format(amount);

export const formatCurrencyNumber = (amount, country = "ar-AR") => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);
  const formattedAmount = numberFraction(country).format(absAmount);
  return isNegative ? `(${formattedAmount})` : formattedAmount;
};

// Dynamic Object Data Utils

export const getFullName = (record) => {
  if (isEmpty(record)) return "";

  let fullName = "";

  if (record.firstName) {
    fullName += record.firstName;
  }

  if (record.middleName) {
    fullName += ` ${record.middleName}`;
  }

  if (record.lastName) {
    fullName += ` ${record.lastName}`;
  }

  if (record.name) {
    fullName = record.name;
  }

  if (record.companyName) {
    fullName = record.companyName;
  }

  return fullName;
};

export const getPaymentDate = ({
  agreementStartDate, agreementEndDate, noOfPayments, index
}) => {
  const months = differenceInMonths(addDays(agreementEndDate, 1), agreementStartDate);

  if (months % noOfPayments === 0) {
    const paymentDate = addMonths(agreementStartDate, (months / noOfPayments) * index);
    return paymentDate;
  }

  const days = differenceInDays(agreementEndDate, agreementStartDate) + 1;
  const paymentDate = addDays(agreementStartDate, Math.ceil(days / noOfPayments) * index);

  return paymentDate;
};

export const formatDecimalValues = (number) => {
  const precision = getPrecision();
  if (!number) {
    return Number(0).toFixed(precision);
  }
  return number.toFixed(precision);
};

export const calculateDiscount = ({
  amount, discountType, discount, noOfPayments
}) => {
  if (discountType.value === "Amount") {
    if (noOfPayments) {
      return formatDecimalValues(discount / noOfPayments);
    }

    return discount;
  }

  if (discountType.value === "Percentage") {
    const discountAmount = (amount * discount) / 100;
    return formatDecimalValues(discountAmount);
  }
};

export const calculateTax = ({
  amount, amountOfTax, tax, taxAmount: prevTaxAmount
}) => {
  let taxAmount = "";
  let principalAmount;

  const { saleRate, purchaseRate } = tax;
  const taxRate = saleRate || purchaseRate;

  switch (amountOfTax.value) {
    case "Inclusive": {
      const totalPercent = taxRate + 100;
      principalAmount = amount * (100 / totalPercent);
      taxAmount = (principalAmount * taxRate) / 100;
      break;
    }

    case "Exclusive": {
      principalAmount = Number(amount) + Number(prevTaxAmount || 0);
      taxAmount = (principalAmount * taxRate) / 100;
      break;
    }

    default:
      principalAmount = amount;
      break;
  }

  return {
    taxAmount: formatDecimalValues(taxAmount || 0),
    principalAmount: formatDecimalValues(principalAmount),
  };
};

export const handleChequeNumberChange = (chequeNo) => {
  let value = chequeNo;
  const maxLength = 6;
  while (value?.length < maxLength) {
    value = `0${value}`;
  }
  if (value?.length > maxLength) {
    value = value.substring(0, maxLength);
  }

  return value;
};

export function chunkArray(arr, chunkSize) {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

// Testing utils

export const getTestId = (id) => {
  if (!id) return "";

  if (appSettings.generateTestId) {
    return id;
  }

  return "";
};

export const getDateFormat = () => {
  let dateFormat = localStorage.getItem("date_format");

  if (!dateFormat || dateFormat === "null") {
    dateFormat = "dd/MM/yyyy";
  }

  if (dateFormat === "dd/mm/yyyy") {
    dateFormat = "dd/MM/yyyy";
  }

  if (dateFormat === "mm/dd/yyyy") {
    dateFormat = "MM/dd/yyyy";
  }

  return dateFormat;
};

export const formatDate = (date) => {
  const dateFormat = getDateFormat();

  return format(date, dateFormat);
};

export const formatApiPayloadDate = (date) => format(date, "yyyy-MM-dd");
