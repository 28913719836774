import {
  useMutation, useQueryClient, useQuery,
} from "@tanstack/react-query";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Button, Loader } from "@hydra/atom/components";
import {
  useParams,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {
  BoxedContent, Header,
  SvgIcon,
  HeaderLeftContent
} from "@/components/common";
import { GeneralJournalEntrySpreadSheet } from "@/components/finance";
import { selectActiveCompany } from "@/store/appSlice";
import {
  uploadGLEntryExcelFile, getJournalEntryById, getJournalEntryDetailById, deleteJournalEntryDetailById
} from "@/api/finance/journalEntryApi";
import { CustomActionDropdown } from "@/components/dynamic";
import appSettings from "@/settings";
import showToast from "@/utils/toast/helpers";
import { useModal } from "@/hooks";
import { AlertModal } from "@/components/modals";

function convertArrayToObjects(dataArray) {
  const convertedData = {};
  dataArray.forEach((item) => {
    convertedData[item.type] = {
      label: item.name,
      value: item.id
    };
  });
  return convertedData;
}

const costCenterObjectNames = [
  "tenant",
  "supplier",
  "employee",
  "building",
  "component",
  "unit",
  "location",
  "organization",
  "project",
  "class",
];

const formatImportJournalEntries = (items) => items.map((item) => {
  const filteredKeys = Object.keys(item).filter((key) => costCenterObjectNames.includes(key));
  const newItem = {};

  filteredKeys.forEach((key) => {
    newItem[key] = { label: item[key].name, value: item[key].id };
  });
  return {
    account: {
      label: item?.account?.name,
      value: item?.account?.id
    },
    tax: null,
    debit: Number(item?.debit || 0),
    credit: Number(item?.credit || 0),
    memo: item.description,
    costCenter: newItem,
    billable: item.IsBillable,
    totalAmount: item.debit || item.credit
  };
});

function JournalEntry() {
  const navigate = useNavigate();
  const { id } = useParams();
  const activeCompany = useSelector(selectActiveCompany);
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();
  const [importedJournalEntries, setImportedJournalEntries] = useState([]);
  const [searchParams] = useSearchParams();
  const cloneId = searchParams.get("journal-entry");
  const reversal = searchParams.get("reversal");
  const [selectedId, setSelectedId] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);

  const importMutation = useMutation(({ file, companyId }) =>
    uploadGLEntryExcelFile({
      file,
      companyId,
    })
  );

  const deleteMutation = useMutation(
    deleteJournalEntryDetailById,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["journal-entry-list"]
        });
      },
    }
  );

  const onUploadFile = (event) => {
    const file = event.target.files[0];

    const toastId = toast.loading("Importing Journal Entries...");

    importMutation.mutate(
      {
        file,
        companyId: activeCompany.id,
      },
      {
        onError: () => {
          toast.error("Could not import the journal entries. Try again!", {
            id: toastId,
          });
        },
        onSuccess: (data) => {
          toast.success("Journal Entries imported successfully!", {
            id: toastId,
          });
          setImportedJournalEntries(formatImportJournalEntries(data.data));
          queryClient.invalidateQueries({
            queryKey: ["journal-entry"],
          });
        },
      }
    );
  };
  const { isLoading, data } = useQuery(
    ["journal-entry-list", id, cloneId],
    () => getJournalEntryById(id || cloneId),
    {
      enabled: Boolean(id || cloneId),
    }
  );
  const { isLoading: isLoadingData, data: dataByID } = useQuery(
    ["journal-entry-list-by-id", id, cloneId],
    () => getJournalEntryDetailById(id || cloneId),
    {
      enabled: Boolean(id || cloneId),
    }
  );

  const formatJournalEntry = (items) => items.map((item) => ({
    account: {
      label: item?.debitAccount?.name || item?.creditAccount?.name,
      value: item?.debitAccount?.id || item?.creditAccount?.id
    },
    tax: null,
    debit: reversal ? Number(item?.creditAccount ? item.balance : 0) : Number(item?.debitAccount ? item.balance : 0),
    credit: reversal ? Number(item?.debitAccount ? item.balance : 0) : Number(item?.creditAccount ? item.balance : 0),
    memo: item?.description ?? "",
    costCenter: convertArrayToObjects(item?.costCenters),
    billable: false,
    totalAmount: item.balance
  }));

  if ((id || cloneId) && (isLoadingData || isLoading)) {
    return <Loader />;
  }
  const getEntryData = () => {
    if (isLoadingData || !(cloneId || id)) {
      return {};
    }

    return {
      number: dataByID?.data?.number,
      date: dataByID?.data?.vouchers[0].postingDate,
      isClone: Boolean(cloneId)
    };
  };

  const filteredActions = () => {
    const { baseUrl } = appSettings;

    const actions = [
      {
        title: "Clone",
        onClick: () => navigate(`/finance/journal-entry/new?journal-entry=${id}`),
        icon: "upload-cloud-stroke-icon",
        actionType: "Clone"
      },
      {
        title: "Reversal",
        onClick: () => navigate(`/finance/journal-entry/new?journal-entry=${id}&reversal=true`),
        icon: "edit-icon",
        actionType: "Reversal"
      },
      {
        title: "Print",
        onClick: () => {
          window.open(`${baseUrl}/reports/journal-entry?Number=${dataByID?.data?.number}&CompanyId=${activeCompany.id}`, "_blank");
        },
        icon: "printer-icon",
        actionType: "Print"
      }
    ];
    return actions;
  };

  return (
    <BoxedContent>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onUploadFile}
        onClick={(event) => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
        accept=".xlsx"
      />
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Journal Entry" icon="currency-dollar-circle-stroke-icon" />}
        rightContent={(
          <div className="buttons-container buttons-at-end journal-entry-actions">
            {id ? (
              <div className="action-cell">
                <CustomActionDropdown
                  actions={filteredActions()}
                  trigger={(
                    <div className="action-dropdown-trigger">
                      <span className="text">Actions</span>
                      <span className="material-icons-outlined">expand_more</span>
                    </div>
                )}
                  objectName="journal-entry"
                />
              </div>
            ) : (
              <Button
                small
                onClick={() => fileInputRef.current.click()}
                className="btn-with-icon"
                loading={importMutation.isLoading}
                bordered
              >
                <Button.Prepend>
                  <SvgIcon icon="upload-cloud-stroke-icon" />
                </Button.Prepend>
                Import
              </Button>
            )}
          </div>
        )}
      />
      <GeneralJournalEntrySpreadSheet
        readOnly={Boolean(id)}
        journalEntryData={!isLoading ? formatJournalEntry(data?.data) : []}
        entryData={!isLoadingData && (cloneId || id) ? getEntryData() : {}}
        importedData={importedJournalEntries}
      />
      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={() => {
          deleteMutation.mutate(id);
          closeModal();
          navigate(-1);
        }}
      />
    </BoxedContent>
  );
}

export default JournalEntry;
