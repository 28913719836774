import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase, startCase } from "lodash";
import { Button } from "@hydra/atom/components";

import { AlertModal } from "@/components/modals";
import { formatDate, formatDecimalValues, calculateTax } from "@/utils/helpers";
import {
  getAMCInvoiceTableColumns,
  getPurchaseInvoiceTableColumns,
  getExpenseTableColumns,
} from "@/components/finance/account-receivables/tableWithCheckboxData";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";

import { getTaxRules } from "@/api/finance/taxRuleApi";

const formatPurchaseInvoice = (purchaseInvoice, taxItem) => {
  const data = {
    key: purchaseInvoice.id,
    number: purchaseInvoice.number,
    purchaseInvoice: {
      label: purchaseInvoice.number,
      value: purchaseInvoice.id,
    },
    itemType: {
      label: purchaseInvoice.itemType,
      value: purchaseInvoice.itemType,
    },
    purchaseInvoiceStatus: purchaseInvoice?.status,
    openBalance: purchaseInvoice?.openBalance,
    taxAmount: purchaseInvoice?.taxAmount,
    subtotal: purchaseInvoice?.discountedTotal || purchaseInvoice?.subtotal,
    total: purchaseInvoice?.total,
    createdAt: purchaseInvoice.createdAt,
    isSelected: false,
  };

  if (purchaseInvoice.amountOfTax) {
    data.amountOfTax = {
      label: startCase(purchaseInvoice.amountOfTax),
      value: purchaseInvoice.amountOfTax,
    };
  }

  data.itemDetail = purchaseInvoice.itemDetail.map((payment) => {
    const lineItem = {
      ...payment,
      paymentFor: {
        lookupObjectName: "PurchaseInvoice",
        label: purchaseInvoice.number,
        value: purchaseInvoice.id,
      },
      amount: Number(payment?.amount) - Number(payment?.discountAmount),
      building: {
        label: payment?.asset?.name,
        value: payment?.asset?.id,
      },
      account: {
        label: payment?.account?.name,
        value: payment?.account?.id,
      },
      isSelected: true,
      memo: `Payment for Purchase Invoice ${purchaseInvoice.number}`,
    };

    if (taxItem) {
      lineItem.tax = {
        ...taxItem,
        label: taxItem?.name,
        value: taxItem?.id,
      };
    }

    return lineItem;
  });

  return data;
};

const formatAMCInvoice = (invoice) => {
  const {
    id,
    number,
    status,
    dueDate,
    total,
    supplier,
    blanketAgreement,
    openBalance,
    supplierInvoiceNr,
    supplierInvoiceDate,
    invoiceReceivedDate,
  } = invoice;

  const data = {
    key: invoice.id,
    number: invoice.number,
    bankAccount: null,
    account: null,
    paymentMethod: {
      label: "Cheque",
      value: "Cheque",
    },
    paymentType: {
      label: "Invoice Payment",
      value: "InvoicePayment",
    },
    dueDate: formatDate(new Date(dueDate)),
    amcInvoice: [
      {
        label: number,
        value: id,
      },
    ],
    supplierInvoiceDate: supplierInvoiceDate ? formatDate(new Date(supplierInvoiceDate)) : "",
    invoiceReceivedDate: invoiceReceivedDate ? formatDate(new Date(invoiceReceivedDate)) : "",
    supplierInvoiceNr,
    amcInvoiceStatus: status,
    total: formatDecimalValues(Number(total)),
    openBalance: formatDecimalValues(Number(openBalance)),
    memo: `Payment for AMC Agreement (${blanketAgreement.number}) to Supplier (${supplier.number}) for Invoice ${number}`,
  };
  data.itemDetail = invoice.lineItem.map((payment) => {
    const lineItem = {
      ...payment,
      paymentFor: {
        lookupObjectName: "AmcInvoice",
        label: invoice?.number,
        value: invoice?.id,
      },
      amount: Number(payment?.amount),
      totalAmount: payment?.totalAmount,
      building: {
        label: payment?.asset?.name,
        value: payment?.asset?.id,
      },
      account: {
        label: payment?.account?.name,
        value: payment?.account?.id,
      },
      isSelected: false,
      memo: payment?.description,
    };

    return lineItem;
  });
  return data;
};

const formatExpense = (expense) => {
  const {
    id, number, vendor, status, dueDate, openBalance, total
  } = expense;

  const data = {
    key: id,
    number,
    expense: [
      {
        label: number,
        value: id,
      },
    ],
    paymentType: {
      label: "Invoice Payment",
      value: "InvoicePayment",
    },
    payee: {
      lookupObjectName: vendor.objectName,
      label: vendor.name,
      value: vendor.id,
      number: vendor.number,
    },
    dueDate: formatDate(new Date(dueDate)),
    expenseStatus: status,
    bankAccount: null,
    account: null,
    paymentMethod: {
      label: "Cheque",
      value: "Cheque",
    },
    memo: `Payment to Supplier (${vendor.number}) for Expense (${number}) `,
    total: formatDecimalValues(Number(total)),
    openBalance: formatDecimalValues(Number(openBalance)),
  };

  data.itemDetail = expense.expenseDetail.map((payment) => {
    const lineItem = {
      ...payment,
      paymentFor: {
        lookupObjectName: "Expense",
        label: expense.number,
        value: expense.id,
      },
      amount: Number(payment?.amount),
      totalAmount: payment.totalAmount,
      asset: {
        label: payment?.asset?.name,
        value: payment?.asset?.id,
        lookupObjectName: payment?.asset?.objectName,
      },
      account: {
        label: payment?.account?.name,
        value: payment?.account?.id,
      },
      isSelected: true,
      memo: `Payment for Expense ${expense.number}`,
    };

    return lineItem;
  });

  return data;
};

const calculateTotalAndTax = ({ itemDetail }) => {
  const data = {
    subtotal: 0,
    taxAmount: 0,
    total: 0,
  };

  data.subtotal = itemDetail.reduce(
    (prevValue, currentValue) => Number(prevValue) + Number(currentValue.amount),
    0
  );

  data.taxAmount = itemDetail.reduce(
    (prevValue, currentValue) => Number(prevValue) + Number(currentValue.taxAmount),
    0
  );
  data.total = Number(data.subtotal) + Number(data.taxAmount);

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select invoice
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function SupplierCreditNoteForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  // const [invoiceTableData, setInvoiceTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const [amcInvoicesTableData, setAMCInvoicesTableData] = useState([]);
  const [purchaseInvoiceTableData, setPurchaseInvoiceTableData] = useState([]);
  const [expenseTableData, setExpenseTableData] = useState([]);

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);

  const handleAMCChange = (e) => {
    setAMCInvoicesTableData(e);
  };

  const handlePurchaseInvoiceChange = (e) => {
    setPurchaseInvoiceTableData(e);
  };

  const handleExpenseChange = (e) => {
    setExpenseTableData(e);
  };

  const { data: amcInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("AmcInvoiceObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("AmcInvoiceObjectName")), {
        supplier: state?.supplier?.value,
        "status[in]": "Partial,Approved",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  const { data: purchaseInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("PurchaseInvoiceObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("PurchaseInvoiceObjectName")), {
        supplier: state?.supplier?.value,
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        "status[in]": "Partial,Approved",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  const { data: expensesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ExpenseObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ExpenseObjectName")), {
        vendor: state?.supplier?.value,
        "status[in]": "Partial,Approved",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  useEffect(() => {
    if (purchaseInvoicesData && purchaseInvoicesData?.data && state?.supplier) {
      const formattedPaymentInvoices = purchaseInvoicesData.data.map((invoice) =>
        formatPurchaseInvoice(invoice)
      );
      if (purchaseInvoicesData?.data?.length) {
        setPurchaseInvoiceTableData(formattedPaymentInvoices);
      } else {
        setPurchaseInvoiceTableData([]);
      }
      openModal();
    }
  }, [purchaseInvoicesData, state?.supplier?.value]);

  useEffect(() => {
    if (amcInvoicesData && amcInvoicesData?.data && state?.supplier) {
      const formattedAMCInvoices = amcInvoicesData.data.map((invoice) => formatAMCInvoice(invoice));
      if (amcInvoicesData?.data?.length) {
        setAMCInvoicesTableData(formattedAMCInvoices);
      } else {
        setAMCInvoicesTableData([]);
      }
      openModal();
    }
  }, [amcInvoicesData, state?.supplier?.value]);

  useEffect(() => {
    if (expensesData && expensesData?.data && state?.supplier) {
      const formattedExpenses = expensesData.data.map((expense) => formatExpense(expense));
      if (expensesData?.data?.length) {
        setExpenseTableData(formattedExpenses);
      } else {
        setExpenseTableData([]);
      }
      openModal();
    }
  }, [expensesData, state?.supplier?.value]);

  const updateItemDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, itemDetail } = formState;

    const updatedItemDetail = itemDetail.map((item) => {
      let { amount } = item;
      const { tax, taxAmount: prevTaxAmount } = item;
      let taxAmount = 0;

      if (tax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax,
          taxAmount: "",
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      return {
        ...item,
        amount,
        tax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      };
    });

    const totalData = calculateTotalAndTax({
      itemDetail: updatedItemDetail,
    });

    ref.current.setFormState({
      itemDetail: updatedItemDetail,
      ...totalData,
    });
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "supplier":
        setState({
          ...state,
          [key]: value,
        });
        if (value) {
          ref.current.setFormValue("tRN", value.tRN);
        }
        break;

      case "itemDetail":
        updateItemDetail(key, value);
        break;

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        updateItemDetail(key, value);
        break;
      }

      default:
        break;
    }
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "itemDetail") {
      switch (key) {
        case "building": {
          parentFieldState[key] = value;
          const { building } = parentFieldState;

          if (building) {
            const { emirate } = building;

            const taxItem = taxRulesData?.data
              .filter((t) => t.purchaseCollected)
              .find((i) => i.region === emirate?.id);

            if (taxItem) {
              parentFieldState.tax = {
                ...taxItem,
                label: taxItem?.name,
                value: taxItem?.id,
              };
            }
          }
          break;
        }

        case "amount":
        case "tax": {
          parentFieldState[key] = value;
          const { amount, tax } = parentFieldState;

          const { amountOfTax } = formState;

          if (tax && amountOfTax) {
            const parameters = {
              amount,
              amountOfTax,
              tax,
              taxAmount: "",
            };
            const { taxAmount, principalAmount } = calculateTax(parameters);
            parentFieldState.taxAmount = taxAmount;
            parentFieldState.totalAmount = Number(principalAmount) + Number(taxAmount);
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const combineInvoices = (amcInvoices, purchaseInvoices, expenses) => {
    const data = {
      expense: [],
      purchaseInvoice: [],
      amcInvoice: [],
      itemDetail: [],
    };

    amcInvoices.forEach((invoice) => {
      const { amcInvoice, itemDetail } = invoice;

      data.amcInvoice = data.amcInvoice.concat(amcInvoice);
      data.itemDetail = data.itemDetail.concat(itemDetail);
    });

    const amcInvoiceNumbers = amcInvoices.map((i) => i.number).join(", ");
    purchaseInvoices.forEach((invoice) => {
      const { purchaseInvoice, itemDetail } = invoice;

      data.purchaseInvoice = data.purchaseInvoice.concat(purchaseInvoice);
      data.itemDetail = data.itemDetail.concat(itemDetail);
    });
    const purchaseInvoiceNumbers = purchaseInvoices.map((i) => i.number).join(", ");

    expenses.forEach((expenseDetail) => {
      const { expense, itemDetail } = expenseDetail;

      data.expense = data.expense.concat(expense);
      data.itemDetail = data.itemDetail.concat(itemDetail);
    });

    const expenseNumbers = expenses.map((i) => i.number).join(", ");

    data.memo = `Payment for ${amcInvoiceNumbers} ${purchaseInvoiceNumbers} ${expenseNumbers}`;

    return data;
  };

  const handleConfirm = () => {
    const selectedAMCInvoices = amcInvoicesTableData.filter((r) => r.isSelected);
    const selectedPurchaseInvoices = purchaseInvoiceTableData.filter((r) => r.isSelected);
    const selectedExpenses = expenseTableData.filter((r) => r.isSelected);
    const combinedInvoices = combineInvoices(
      selectedAMCInvoices,
      selectedPurchaseInvoices,
      selectedExpenses
    );
    const totalData = calculateTotalAndTax({
      itemDetail: combinedInvoices.itemDetail,
    });

    ref.current.setFormState({
      itemDetail: combinedInvoices.itemDetail,
      ...totalData,
    });
    ref.current.setFormState(combinedInvoices);

    closeModal();

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Supplier Invoice"
        subtitle="Selected supplier has following partial/paid invoices"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {amcInvoicesTableData.length ? (
          <TableWithCheckbox
            data={amcInvoicesTableData}
            setData={(e) => handleAMCChange(e)}
            columns={getAMCInvoiceTableColumns()}
            allowMultiple
          />
        ) : null}
        {purchaseInvoiceTableData.length ? (
          <TableWithCheckbox
            data={purchaseInvoiceTableData}
            setData={(e) => handlePurchaseInvoiceChange(e)}
            columns={getPurchaseInvoiceTableColumns()}
            allowMultiple
          />
        ) : null}
        {expenseTableData.length ? (
          <TableWithCheckbox
            data={expenseTableData}
            setData={(e) => handleExpenseChange(e)}
            columns={getExpenseTableColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("SupplierCreditNoteObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(
                purchaseInvoicesData?.length || amcInvoicesData?.length || expensesData?.length
              ),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default SupplierCreditNoteForm;
