import { useEffect, useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Checkbox, Button,
  Input,
} from "@hydra/atom/components";
import { startCase } from "lodash";
import {
  isAfter, isEqual,
  areIntervalsOverlapping,
  isBefore
} from "date-fns";
import {
  BoxedContent, Header, Accordion,
  FormLeftHeader,
} from "@/components/common";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import {
  closeAccountingPeriod, reopenAccountingPeriod,
  getAccountingPeriodById,
} from "@/api/finance/accountingPeriodApi";
import StaticObjectDetails from "@/pages/finance/detail-views/StaticObjectDetails";
import showToast from "@/utils/toast/helpers";
import { formatApiPayloadDate } from "@/utils/helpers";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";

const isDateEqualOrAfter = (referenceDate, comparisonDate) =>
  isEqual(referenceDate, comparisonDate) ||
isAfter(referenceDate, comparisonDate);

const isDateEqualOrBefore = (referenceDate, comparisonDate) =>
  isEqual(referenceDate, comparisonDate) ||
isBefore(referenceDate, comparisonDate);

const formatDataForRevenueRecognition = (response, startDate, endDate) => {
  if (!response || response?.data.length === 0) return response;

  const filteredData = response?.data.filter(
    ({
      agreementStartDate, agreementEndDate, contractTerminationDate, moveOutDate
    }) => {
      let terminationDate = null;
      let tenantMoveOutDate = null;

      if (moveOutDate) {
        tenantMoveOutDate = new Date(moveOutDate);

        if (isAfter(new Date(startDate), tenantMoveOutDate)) {
          return false;
        }
      }

      if (contractTerminationDate && !moveOutDate) {
        terminationDate = new Date(contractTerminationDate);

        if (isAfter(new Date(startDate), terminationDate)) {
          return false;
        }
      }

      try {
        const isOverlapping = areIntervalsOverlapping(
          {
            start: new Date(agreementStartDate),
            end: new Date(agreementEndDate),
          },
          {
            start: new Date(startDate),
            end: new Date(endDate),
          },
          {
            inclusive: true,
          }
        );

        if (isOverlapping) return true;
      } catch (error) {
        return false;
      }

      return false;
    }
  );

  const result = filteredData.filter((item) => {
    const {
      contractTerminationDate, addUtilityCharge,
      lastRentalRevenueRecognitionDate, lastUtilityRevenueRecognitionDate, moveOutDate
    } = item;

    if (!lastRentalRevenueRecognitionDate) {
      return true;
    }

    if (addUtilityCharge && !lastUtilityRevenueRecognitionDate) {
      return true;
    }

    if (moveOutDate && !isAfter(new Date(moveOutDate), endDate)) {
      if (addUtilityCharge) {
        return (
          isDateEqualOrAfter(new Date(lastRentalRevenueRecognitionDate), moveOutDate) &&
               isDateEqualOrAfter(new Date(lastUtilityRevenueRecognitionDate), moveOutDate));
      }
      return isDateEqualOrAfter(lastRentalRevenueRecognitionDate, new Date(moveOutDate));
    }

    if (contractTerminationDate &&
       !moveOutDate && !isAfter(new Date(contractTerminationDate), endDate)) {
      if (addUtilityCharge) {
        return (
          isDateEqualOrAfter(new Date(lastRentalRevenueRecognitionDate), contractTerminationDate) &&
                 isDateEqualOrAfter(new Date(lastUtilityRevenueRecognitionDate),
                   contractTerminationDate));
      }
      return isDateEqualOrAfter(lastRentalRevenueRecognitionDate,
        new Date(contractTerminationDate));
    }

    if (addUtilityCharge) {
      return isDateEqualOrAfter(new Date(lastRentalRevenueRecognitionDate), endDate) &&
      isDateEqualOrAfter(new Date(lastUtilityRevenueRecognitionDate), endDate);
    }

    return isDateEqualOrAfter(new Date(lastRentalRevenueRecognitionDate), endDate);
  });

  return { ...response, data: result };
};

const formatDataForExpenseRecognition = (response, startDate, endDate) => {
  if (!response || response?.data.length === 0) return response;

  const filteredData = response?.data.filter(({ agreementStartDate, agreementEndDate }) => {
    try {
      const isOverlapping = areIntervalsOverlapping(
        {
          start: new Date(agreementStartDate),
          end: new Date(agreementEndDate),
        },
        {
          start: new Date(startDate),
          end: new Date(endDate),
        },
        {
          inclusive: true,
        }
      );

      if (isOverlapping) return true;
    } catch (error) {
      return false;
    }

    return false;
  });

  const result = filteredData.filter((item) => {
    const {
      terminationDate,
      lastRecognitionDate,
    } = item;

    if (!lastRecognitionDate) {
      return true;
    }

    if (terminationDate && !isAfter(new Date(terminationDate), endDate)) {
      return isDateEqualOrAfter(lastRecognitionDate,
        new Date(terminationDate));
    }

    return isDateEqualOrAfter(new Date(lastRecognitionDate), endDate);
  });

  return { ...response, data: result };
};

const formatDataForRecordInvoices = (response, startDate, endDate) => {
  if (!response || !response.data?.length) return response?.data;
  return response.data.flatMap((item) =>
    item?.paymentDetail?.filter((paymentDetailItem) => paymentDetailItem.status === "NotGenerated" && isDateEqualOrAfter(new Date(paymentDetailItem.paymentDate), startDate) &&
      isDateEqualOrBefore(new Date(paymentDetailItem.paymentDate), endDate)
    )
  );
};

const formatDataForItem = (response, startDate, endDate) => {
  if (!response || response?.data.length === 0) return response;

  const result = response?.data.filter((item) => {
    const {

      depreciationDate, disposalDate, retirementDate
    } = item;

    if (!depreciationDate) {
      return true;
    }

    if (retirementDate && !isAfter(new Date(retirementDate), endDate)) {
      return isDateEqualOrAfter(depreciationDate, new Date(retirementDate));
    }

    if (disposalDate && !isAfter(new Date(disposalDate), endDate)) {
      return isDateEqualOrAfter(depreciationDate,
        new Date(disposalDate));
    }

    return isDateEqualOrAfter(new Date(depreciationDate), endDate);
  });

  return { ...response, data: result };
};

const listItems = [
  {
    module: "AccountsReceivable",
    items: [
      {
        type: "api",
        title: "Record all invoices",
        loadingStatus: "Checking all invoices...",
        successStatus: "All invoices verified",
        pendingStatus: "Complete pending tasks for invoices...",
        errorStatus: "Some invoices need to be recorded",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            queryMode: "Deep"
          }),
        formatData: (response, interimPeriodDate) => {

          if (response && interimPeriodDate?.length > 0) {
            const formattedData = formatDataForRecordInvoices(response,
              interimPeriodDate[0],
              interimPeriodDate[1]);
            return formattedData;
          }
        },
        check: (response) => response?.length === 0,
        tasks: [
          {
            label: "Check for pending invoices",
            value: true,
            disabled: true,
          },
          {
            label: "Issue all invoices",
            value: false,
            disabled: false,
          },
          {
            label: "Enter all payments",
            value: false,
            disabled: false,
          },
        ],
      },
      {
        type: "api",
        title: "Revenue Recognition",
        loadingStatus: "Verifying revenue recognition for all contracts...",
        successStatus: "Revenue recognition verified for all contracts",
        pendingStatus: "Complete pending revenue recognition tasks...",
        errorStatus: "Some contracts have pending revenue recognition",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            "status[in]": "Active,Approved,RenewalPending,Ended,Expired,Broken,TerminationInProgress",
          }),
        check: (response) => response?.data?.length === 0,
        formatData: (response, interimPeriodDate) => {

          if (response && interimPeriodDate?.length > 0) {
            const formattedData = formatDataForRevenueRecognition(response,
              interimPeriodDate[0],
              interimPeriodDate[1]);
            return formattedData;
          }
        },
        tasks: [
          {
            label: "Check for revenue recognition",
            value: true,
            disabled: true,
          },
        ],
      },
      {
        type: "api",
        title: "PDC",
        loadingStatus: "Verifying all PDCs...",
        successStatus: "All PDCs are cleared for the current period",
        pendingStatus: "Complete pending PDC tasks...",
        errorStatus: "Some PDCs need to be cleared",
        api: (interimPeriodDate) =>
          getDynamicObjectRecords(dynamicObjectMap.get("PDCObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            "dueDate[lte]": formatApiPayloadDate(new Date(interimPeriodDate[1])),
            "dueDate[gte]": formatApiPayloadDate(new Date(interimPeriodDate[0])),
            status: "Cleared"
          }),
        check: (response) => response?.data?.length === 0,
        tasks: [
          {
            label: "Verify cleared PDCs",
            value: true,
            disabled: true,
          },
        ],
      },
      {
        type: "link",
        title: "Reconcile AR Subledger to GL",
        loadingStatus: "Generating customer aging report...",
        successStatus: "Customer aging report generated",
        pendingStatus: "Complete pending tasks for reconciliation...",
        errorStatus: "Could not generate customer aging report",
        check: () => true,
        tasks: [
          {
            label: "Verify customer aging report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/tenant-aging",
            },
          },
          {
            label: "Match with GL",
            value: false,
            disabled: false,
          },
          {
            label: "Resolve discrepancies",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    module: "AccountsPayable",
    items: [
      {
        type: "api",
        title: "Record all invoices",
        loadingStatus: "Checking all invoices...",
        successStatus: "All invoices verified",
        pendingStatus: "Complete pending tasks for invoices...",
        errorStatus: "Some invoices need to be recorded",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("BlanketAgreementObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            queryMode: "Deep"
          }),
        check: (response) => response?.length === 0,
        formatData: (response, interimPeriodDate) => {
          if (response && interimPeriodDate?.length > 0) {
            const formattedData = formatDataForRecordInvoices(response,
              interimPeriodDate[0],
              interimPeriodDate[1]);
            return formattedData;
          }
        },
        tasks: [
          {
            label: "Check for pending invoices",
            value: true,
            disabled: true,
          },
          {
            label: "Issue all invoices",
            value: false,
            disabled: false,
          },
          {
            label: "Enter all payments",
            value: false,
            disabled: false,
          },
        ],
      },
      {
        type: "api",
        title: "Expense Recognition",
        loadingStatus: "Verifying Expense recognition for all contracts...",
        successStatus: "Expense recognition verified for all contracts",
        pendingStatus: "Complete pending expense recognition tasks...",
        errorStatus: "Some contracts have pending expense recognition",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("BlanketAgreementObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            "status[in]": "Active,Planned,RenewalPending,Ended,Expired,Broken",
          }),
        check: (response) => response?.data?.length === 0,
        formatData: (response, interimPeriodDate) => {

          if (response && interimPeriodDate?.length > 0) {
            const formattedData = formatDataForExpenseRecognition(response,
              interimPeriodDate[0],
              interimPeriodDate[1]);
            return formattedData;
          }
        },
        tasks: [
          {
            label: "Check for expense recognition",
            value: true,
            disabled: true,
          },
        ],
      },
      {
        type: "link",
        title: "Reconcile AP Subledger to GL",
        loadingStatus: "Generating customer aging report...",
        successStatus: "Customer aging report generated",
        pendingStatus: "Complete pending tasks for reconciliation...",
        errorStatus: "Could not generate customer aging report",
        check: () => true,
        tasks: [
          {
            label: "Verify supplier aging report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/vendor-aging",
            },
          },
          {
            label: "Match with GL",
            value: false,
            disabled: false,
          },
          {
            label: "Resolve discrepancies",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    module: "FixedAssets",
    items: [
      {
        type: "api",
        title: "Verify all Fixed Asset Creation Requests",
        loadingStatus: "Checking all Fixed Asset Creation Requests...",
        successStatus: "All Fixed Asset Creation Requests Approved",
        pendingStatus: "Complete pending tasks for asset creation requests...",
        errorStatus: "Some Fixed Asset Creation Requests need approval",
        api: (interimPeriodDate) =>
          getDynamicObjectRecords(dynamicObjectMap.get("FixedAssetCreationRequestObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            "status[in]": "Open,InProgress,Rejected",
            "createdAt[gte]": interimPeriodDate[0],
            "createdAt[lte]": interimPeriodDate[1],
          }),
        check: (response) => response?.data?.length === 0,
        tasks: [
          {
            label: "Check for pending asset creation requests",
            value: true,
            disabled: true,
          },
          {
            label: "Approve all asset creation requests",
            value: false,
            disabled: false,
          },
          {
            label: "Finalize all approved assets",
            value: false,
            disabled: false,
          },
        ],
      },
      {
        type: "api",
        title: "Verify all Disposal Requests",
        loadingStatus: "Checking all Disposal Requests...",
        successStatus: "All Disposal Requests Disposed",
        pendingStatus: "Complete pending tasks for disposal requests...",
        errorStatus: "Some Disposal Requests need to be disposed",
        api: (interimPeriodDate) =>
          getDynamicObjectRecords(dynamicObjectMap.get("DisposalObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            "status[in]": "Open,InProgress,Rejected,Approved",
            "createdAt[gte]": interimPeriodDate[0],
            "createdAt[lte]": interimPeriodDate[1],
          }),
        check: (response) => response?.data?.length === 0,
        tasks: [
          {
            label: "Check for pending disposal requests",
            value: true,
            disabled: true,
          },
          {
            label: "Dispose all requests",
            value: false,
            disabled: false,
          },
          {
            label: "Finalize all disposed requests",
            value: false,
            disabled: false,
          },
        ],
      },
      {
        type: "api",
        title: "Verify Depreciation Status for Interim Period",
        loadingStatus: "Checking depreciation status for all items...",
        successStatus: "All assets are depreciated for the interim period",
        pendingStatus: "Complete pending depreciation tasks...",
        errorStatus: "Some assets are not depreciated for the interim period",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("ItemObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            isDepreciated: true,
            itemType: "Asset",
            status: "Active"
          }),
        check: (response) => response?.data?.length === 0,
        formatData: (response, interimPeriodDate) => {

          if (response && interimPeriodDate?.length > 0) {
            const formattedData = formatDataForItem(response,
              interimPeriodDate[0],
              interimPeriodDate[1]);
            return formattedData;
          }
        },
        tasks: [
          {
            label: "Check for pending depreciation tasks",
            value: true,
            disabled: true,
          },
          {
            label: "Depreciate all pending assets",
            value: false,
            disabled: false,
          },
          {
            label: "Finalize depreciated assets",
            value: false,
            disabled: false,
          },
        ],
      },
      {
        type: "link",
        title: "Reconcile Fixed Asset with Ledger to GL",
        loadingStatus: "Generating fixed asset report...",
        successStatus: "Fixed asset report generated",
        pendingStatus: "Complete pending tasks for reconciliation...",
        errorStatus: "Could not generate fixed asset report",
        check: () => true,
        tasks: [
          {
            label: "Verify fixed asset report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/fixed-asset-listing",
            },
          },
          {
            label: "Match with GL",
            value: false,
            disabled: false,
          },
          {
            label: "Resolve discrepancies",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    module: "GeneralLedger",
    items: [
      {
        type: "link",
        title: "Check Trial Balance Report",
        loadingStatus: "Generating trial balance report...",
        successStatus: "Trial balance report generated",
        pendingStatus: "Complete pending tasks for trial balance...",
        errorStatus: "Could not generate trial balance report",
        check: () => true,
        tasks: [
          {
            label: "Verify trial balance report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/trial-balance",
            },
          }
        ],
      },
      {
        type: "link",
        title: "Check VAT Summary Report",
        loadingStatus: "Generating VAT summary report...",
        successStatus: "VAT summary report generated",
        pendingStatus: "Complete pending tasks for VAT summary...",
        errorStatus: "Could not generate VAT summary report",
        check: () => true,
        tasks: [
          {
            label: "Verify VAT summary report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/vat-summary",
            },
          },
        ],
      },
      {
        type: "link",
        title: "Check General Journal",
        loadingStatus: "Generating general journal report...",
        successStatus: "General journal report generated",
        pendingStatus: "Complete pending tasks for general journal...",
        errorStatus: "Could not generate general journal report",
        check: () => true,
        tasks: [
          {
            label: "Verify general journal report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/general-journal",
            },
          },
        ],
      },
      {
        type: "link",
        title: "Check Journal Ledger",
        loadingStatus: "Generating journal ledger report...",
        successStatus: "Journal ledger report generated",
        pendingStatus: "Complete pending tasks for journal ledger...",
        errorStatus: "Could not generate journal ledger report",
        check: () => true,
        tasks: [
          {
            label: "Verify journal ledger report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/journal-ledger",
            },
          }
        ],
      },
    ],
  },
];

const prepareInitialState = (items) => {
  const initialState = {};

  items.forEach((listItem) => {
    listItem.items.forEach((item, itemIndex) => {
      item.tasks.forEach((task, taskIndex) => {
        const checkboxKey = `${listItem.module}${itemIndex}${taskIndex}`;
        initialState[checkboxKey] = task.value;
      });
    });
  });

  return initialState;
};

const renderIcon = (status) => {
  switch (status) {
    case "loading":
      return <i className="fas fa-circle-notch fa-spin" />;

    case "success":
      return <i className="fas fa-check-circle" />;

    case "error":
      return <i className="fas fa-exclamation-circle" />;

    case "pending":
      return <i className="fas fa-circle" />;
    default:
      break;
  }
};

const renderStatus = (status, item) => {
  switch (status) {
    case "loading":
      return <p>{item.loadingStatus}</p>;

    case "pending":
      return <p>{item.pendingStatus}</p>;

    case "success":
      return <p>{item.successStatus}</p>;

    case "error":
      return <p>{item.errorStatus}</p>;

    default:
      break;
  }
};

const getItemCheckboxKeys = (module, itemIndex, item) => {
  const checkboxKeys = [];
  item.tasks.forEach((task, taskIndex) => {
    const checkboxKey = `${module}${itemIndex}${taskIndex}`;
    checkboxKeys.push(checkboxKey);
  });
  return checkboxKeys;
};

const getStatus = (isLoading, module, itemIndex, item, state, data) => {
  if (isLoading) {
    return "loading";
  }

  if (!item.check(data)) {
    return "error";
  }

  const checkboxKeys = getItemCheckboxKeys(module, itemIndex, item);
  let isPending = false;
  if (checkboxKeys.length) {
    for (let i = 0; i < checkboxKeys.length; i += 1) {
      const checkboxKey = checkboxKeys[i];
      const value = state[checkboxKey];
      if (!value) {
        isPending = true;
        break;
      }
    }
  }

  if (isPending) {
    return "pending";
  }

  return "success";
};

function CheckOffCard({
  index, item, module, handleCheckboxChange, state, interimPeriodDate
}) {
  const {
    data,
    isInitialLoading: isLoading,
    refetch,
    isRefetching,
  } = useQuery([item.title], () => item.api(interimPeriodDate), {
    enabled: item.type === "api",
    select: (response) => {
      if (item?.formatData && response) {
        return item?.formatData(response, interimPeriodDate);
      }
      return response;
    }
  });
  const isDataLoading = isLoading || isRefetching;

  const status = getStatus(isDataLoading, module, index, item, state, data);

  const handleRefresh = () => {
    refetch();
  };

  return (
    <div className="check-off-card">
      <div className="check-off-card-item-container">
        <h6>{item.title}</h6>
        {renderIcon(status)}
      </div>
      <div className="check-off-card-status-container">{renderStatus(status, item)}</div>
      {item.tasks.length && status !== "loading" ? (
        <div className="check-off-card-list-container">
          {item.tasks.map((checkboxItem, i) => {
            const checkboxKey = `${module}${index}${i}`;

            return (
              <div key={`Checkbox-List-Item-${i}`} className="check-off-card-list-item-container">
                <Checkbox
                  label={checkboxItem.label}
                  value={state[checkboxKey]}
                  onChange={(value) => handleCheckboxChange(checkboxKey, value)}
                />
                {checkboxItem.link && (
                  <Link to={checkboxItem.link.to} target="_blank">
                    View
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      {status === "error" && (
        <button className="btn-refresh" type="button" aria-label="Refresh" onClick={handleRefresh}>
          <i className="fa fa-refresh" />
        </button>
      )}
    </div>
  );
}

const filterListItems = (items, moduleName) => {
  if (!moduleName) {
    return items;
  }

  const moduleListItems = items.filter((i) => i.module === moduleName);

  if (moduleListItems.length) {
    return moduleListItems;
  }

  return [];
};
function CloseAccountingPeriod() {
  const { id, interimPeriodId } = useParams();
  const [searchParams] = useSearchParams();
  const moduleName = searchParams.get("moduleName");
  const [interimPeriodDate, setInterimPeriodDate] = useState([]);
  const [state, setState] = useState(prepareInitialState(listItems));
  const { isOpen, closeModal, openModal } = useModal(false);
  const [remarks, setRemarks] = useState("");

  const shownListItems = useMemo(() => filterListItems(listItems, moduleName),
    [moduleName]);

  const { data } = useQuery(
    ["accounting-period", id],
    () => getAccountingPeriodById(id),
    {
      enabled: id !== undefined
    }
  );

  useEffect(() => {
    if (data?.data) {
      const currentPeriod = data.data.interimPeriods.find(
        // eslint-disable-next-line eqeqeq
        (item) => item.number == interimPeriodId);
      if (currentPeriod) {
        setInterimPeriodDate([currentPeriod.startDate, currentPeriod.endDate]);
      }
    }
  }, [data]);

  const closeMutation = useMutation(
    ({ number, financeModule }) => closeAccountingPeriod(id, number, financeModule),
    {
      onError: () => {
        showToast("Could not close interim accounting period. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Closed successfully", "success");
      },
    }
  );

  const reopenMutation = useMutation(
    ({ number, financeModule }) => reopenAccountingPeriod(id, number, financeModule),
    {
      onError: () => {
        showToast("Could not reopen interim accounting period. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Reopened successfully", "success");
      },
    }
  );

  const handleAction = () => {
    if (state.isClosed) {
      reopenMutation.mutate({ number: Number(interimPeriodId), financeModule: moduleName, remarks });
    } else {
      closeMutation.mutate({ number: Number(interimPeriodId), financeModule: moduleName, remarks });
    }
  };

  const handleCheckboxChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <BoxedContent className="dynamic-object-overview">
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Add remarks"
        subtitle="Provide Remarks Before Closing the Accounting Period"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleAction}
        size="large"
      >
        <div className="row">
          <div className="col-md-5">
            <FormLeftHeader
              title="Remarks"
              subtitle="Add Remarks Before Finalizing the Accounting Period"
            />
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <Input
                  value={remarks}
                  onChange={(value) => setRemarks(value)}
                  name="Remarks"
                  id="remarks"
                  placeholder="Remarks"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </AlertModal>
      <Header
        showBreadcrumb
        leftContent={(
          <div>
            <h1>Close Interim Accounting Period</h1>
          </div>
        )}
        rightContent={(
          <Button
            small
            onClick={() => openModal()}
            loading={closeMutation.isLoading || reopenMutation.isLoading}
          >
            {`${state?.isClosed ? "Reopen" : "Close"} Interim Accounting Period`}
          </Button>
        )}
      />
      <StaticObjectDetails id={id} showHeader={false} />
      {shownListItems.map((listItem, i) => (
        <Accordion
          key={`Accordion-${i}`}
          title={startCase(listItem.module)}
          count={listItem.items.length}
        >
          <div className="row gy-4">
            {interimPeriodDate.length > 0 && listItem.items.map((item, k) => (
              <div key={`Check-Off-Card-${k}`} className="col-md-3">
                <CheckOffCard
                  index={k}
                  module={listItem.module}
                  item={item}
                  handleCheckboxChange={handleCheckboxChange}
                  state={state}
                  interimPeriodDate={interimPeriodDate}
                />
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </BoxedContent>
  );
}

CloseAccountingPeriod.propTypes = {};

export default CloseAccountingPeriod;
